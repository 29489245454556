@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700;800&display=swap');

/* Main Footer Styles */
#main-footer {
  background: url('/public/footer.jpg') no-repeat center center/cover;
  font-family: 'Exo 2', sans-serif;
  color: #ffffff;
  padding: 3rem 0 0 0;
  position: relative;
  overflow: hidden;
}

/* Add tech circuit pattern background */
#main-footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='rgba(0, 225, 255, 0.05)' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.3;
  z-index: 0;
  pointer-events: none;
}

/* Content container */
.footer-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 1;
}

/* Footer sections */
.footer-section {
  flex: 1;
  min-width: 250px;
  margin-bottom: 2rem;
  padding: 0 1rem;
}

/* Brand section */
.footer-brand {
  flex: 2;
}

.footer-logo {
  max-width: 180px;
  margin-bottom: 1rem;
}

.footer-logo img {
  width: 100%;
  height: auto;
  filter: drop-shadow(0 0 8px rgba(0, 225, 255, 0.6));
}

.footer-tagline {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
  color: #00e1ff;
}

.footer-description {
  font-size: 0.85rem;
  opacity: 0.8;
  letter-spacing: 0.5px;
}

/* Headings */
.footer-heading {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  position: relative;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.footer-heading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  background: linear-gradient(90deg, #00e1ff, transparent);
}

/* Navigation */
.footer-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-nav li {
  margin-bottom: 0.75rem;
}

.footer-link {
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  display: inline-block;
  position: relative;
}

.footer-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: #00e1ff;
  transition: width 0.3s ease;
}

.footer-link:hover {
  color: #00e1ff;
  transform: translateX(5px);
}

.footer-link:hover::after {
  width: 100%;
}

/* Contact section */
.contact-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  font-size: 0.85rem;
}

.contact-icon {
  margin-right: 0.75rem;
  color: #00e1ff;
  font-size: 1.2rem;
}

/* Bottom bar with social links and copyright */
.footer-bottom {
  margin-top: 1rem;
  padding: 1.5rem 0;
  border-top: 1px solid rgba(0, 225, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  gap: 1.5rem;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  color: white;
  transition: all 0.3s ease;
  text-decoration: none;
}

.social-icon:hover {
  background-color: #00e1ff;
  color: #000;
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 225, 255, 0.5);
}