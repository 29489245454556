.container .container-form .container-label .input_field {
    background-color: black;
    height: 40px;
    outline: red;
    border: 1px solid #404040;
    padding-left: 10px;
    color: white;
    margin-top: 5px;
    margin-bottom: 10px;
    /* box-shadow: 0px 0px 0px 0px red;  */
    
}
::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.container .container-form {
    width: 500px;
}

@media screen and (max-width: 720px) {
    .container .container-form {
        width: 300px;
    }
    
}

