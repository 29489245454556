@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;500;700;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Syne:wght@400;500;600;700;800&display=swap");

#main-register {
    position: relative;
    background: black;
    height: 160%;
    width: 100%;
    padding-top: 10px;
    color: #adcee8;
    font-family: Inter;
    display: flex;
    /* justify-content: center; */
}

#part-1 {
    width: 55vw;
    padding: 60px 0px 0px 10px;
    /* position: absolute; */
    color: #adcee8;
    letter-spacing: 1px;
}

#part-1 .fieldset {
    /* margin: 0px 0px 20px 20px; */
    /* padding: 10px; */
    width: 5px;
    border-color: #adcee8;
    border-radius: 10px;
}

legend {
    text-align: center;
    font-size: large;
    letter-spacing: 1px;
}

.flex-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
    width: 45vw;
}

.flex-container div {
    padding: 5px;
}

.flex-2 {
    display: flex;
    flex-direction: row;
}

.flex-2 div {
    width: 20vw;
    margin: 2px;
    padding: 5px;
}

.in {
    width: 18vw;
    color: #adcee8;
    background-color: #566ae5;
    padding: 5px;
    letter-spacing: 1px;
    border-radius: 5px;
    margin: 0px;
    border: 0px;
}
input[type="radio"] {
    width: auto;
    accent-color: #adcee8;
}

input[type="file"] {
    background-color: black;
}

input[type="file"]::file-selector-button {
    background-color: #566ae5;
    border-radius: 5px;
    color: #adcee8;
    padding: 5px;
}

/* input[type="text"]{
    padding: 4px;
} */

::placeholder {
    color: #adcee8;
    padding: 4px;
}

select {
    background-color: #566ae5;
    color: #adcee8;
    border-radius: 5px;
    width: 18.5vw;
    padding: 5px;
    letter-spacing: 1px;
    margin: 0px;
    border: 0px;
}

.button {
    width: 20vw;
    margin: auto;
    position: relative;
    display: flex;
}

.button input:hover {
    background-color: #006bad;
    color: white;    
}

.button input {
    width: 15vw;
    color: #adcee8;
    background-color: #566ae5;
    letter-spacing: 1px;
    border-radius: 5px;
    border: 0px;
    padding: 10px;
    margin: 10px;
    font-size: large;
}

/* #border {
    width: 30vw;
    position: absolute;
} */
/* #accordion{
    width: 60vw;
    padding: 0px 0px 20px 45px;
} */

#imp {
    padding: 20px 0px 20px 20px;
    /* padding: 10px; */
    width: 100%;
    font-size: large;
    /* line-height: 30px; */
}

#part-2 {
    width: 45vw;
    position: absolute;
    right: 4vw;
    top: 5%;
    text-align: right;
    padding-top: 50px;
}

.poster {
    padding: 20px;
    width: 35vw;
    /* border: 5px solid #65FFA3; */
    /* outline: 5px solid #86c7f9; */
    /* border-radius: 5px; */
    outline-offset: -15px;
    outline-width: 200px 200px;
    background: linear-gradient(to right, #86c7f9 4px, transparent 4px) 0 100%,
        linear-gradient(to left, #86c7f9 4px, transparent 4px) 100% 0,
        linear-gradient(to bottom, #86c7f9 4px, transparent 4px) 100% 0,
        linear-gradient(to top, #86c7f9 4px, transparent 4px) 0 100%;

    background-repeat: no-repeat;
    background-size: 200px 200px;
}

@media only screen and (max-width: 600px) {
    .flex-2 {
        flex-direction: column;
    }
    #part-1 {
        /* width: 100vw; */
    }
    .flex-2 div {
        width: 70vw;
        /* margin: 2px; */
        padding: 5px;
    }
    .flex-container {
        width: 70vw;
    }
    .in {
        width: 50vw;
    }
    #part-2 {
        /* position: relative; */
        width: 100%;
        display: none;
    }
    #imp {
        width: 70vw;
    }

    .button {
        width: 80vw;
        pad: -10px;
    }

    .button input {
        width: 40vw;
    }
}
