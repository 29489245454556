@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700&display=swap');

#main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  width: 100%;
  padding: 0 2rem;
  position: fixed;
  top: 0;
  z-index: 11;
  background-color: rgba(31, 2, 77, 0.8);
  backdrop-filter: blur(10px);
}

.left-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.left-container-child {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.left-container-child-nav-logo {
  height: 3rem;
  object-fit: contain;
}

.right-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.right-container-child {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

/* Mobile menu icon */
.right-container-hamburger {
  display: none;
  color: white;
  cursor: pointer;
}

/* Navigation items styling with Exo 2 font */
.nav-item, .nav-item-active {
  position: relative;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: white;
  font-family: 'Exo 2', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  overflow: hidden;
  transition: all 0.3s ease;
}

/* Active item styling */
.nav-item-active {
  color: #00e1ff;
  font-weight: 600;
}

/* New active indicator animation - glowing underline */
.active-indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #00e1ff, transparent);
  animation: glow 1.5s ease-in-out infinite;
}

@keyframes glow {
  0% {
    opacity: 0.3;
    box-shadow: 0 0 5px #00e1ff;
  }
  50% {
    opacity: 1;
    box-shadow: 0 0 15px #00e1ff;
  }
  100% {
    opacity: 0.3;
    box-shadow: 0 0 5px #00e1ff;
  }
}

/* New hover animation - sliding gradient */
.nav-item:hover {
  color: #00e1ff;
}

.hover-effect {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(0, 225, 255, 0.1), transparent);
  transition: all 0.5s ease;
}

.nav-item:hover .hover-effect {
  left: 100%;
}

/* Drawer styling */
#drawer {
  width: 250px;
  height: 100%;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.drawer-header {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.drawer-links {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
}

.drawer-links .nav-item, 
.drawer-links .nav-item-active {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 4px;
}

.drawer-links .nav-item-active {
  background-color: rgba(0, 225, 255, 0.1);
}

/* Media queries */
@media (max-width: 768px) {
  .right-container-child {
    display: none;
  }
  
  .right-container-hamburger {
    display: block;
  }
  
  #main {
    padding: 0 1rem;
    height: 4rem;
  }
}