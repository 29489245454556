/* Global Styles */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700&family=Exo+2:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Exo 2', sans-serif;
  background-color: #0a0a1a;
  color: #ffffff;
  overflow-x: hidden;
}

.landing-space-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0a0a1a;
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
  z-index: -1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Glow animation for letters */
@keyframes letterGlow {
  0% {
    text-shadow: 0 0 5px #4d79ff, 0 0 10px #4d79ff;
  }
  50% {
    text-shadow: 0 0 20px #4d79ff, 0 0 30px #4d79ff, 0 0 40px #4d79ff;
  }
  100% {
    text-shadow: 0 0 5px #4d79ff, 0 0 10px #4d79ff;
  }
}

/* Landing Container */
.landing-container {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

/* Header Section - Keeping original fonts */
.header-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: linear-gradient(rgba(10, 10, 26, 0.7), rgba(10, 10, 26, 0.7)), url('/public/hero.png');
  background-size: cover;
  background-position: center;
}

.sparsh-title {
  text-align: center;
  z-index: 10;
}

.letter-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.letter {
  font-family: 'Orbitron', sans-serif; /* Keeping Orbitron for hero section */
  font-size: 7rem;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 0 10px #4d79ff, 0 0 20px #4d79ff;
  margin: 0 5px;
  animation: letterGlow 3s infinite alternate;
}

.subtitle {
  font-size: 1.5rem;
  color: #c8c8ff;
  letter-spacing: 3px;
  text-transform: uppercase;
}

/* About Section */
.-section {
  padding: 100px 20px;
  background: linear-gradient(rgba(10, 10, 26, 0.8), rgba(10, 10, 26, 0.8)), url('/public/body1.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
}

.about-content h2 {
  font-family: 'Exo 2', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #4d79ff;
}

.about-content p {
  font-family: 'Exo 2', sans-serif;
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 40px;
  color: #d1d1f0;
}

.register-btn {
  display: inline-block;
  padding: 12px 30px;
  background: linear-gradient(45deg, #4d79ff, #9d4edd);
  color: white;
  text-decoration: none;
  border-radius: 30px;
  font-family: 'Exo 2', sans-serif;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 0 15px rgba(77, 121, 255, 0.5);
}

.register-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 0 20px rgba(77, 121, 255, 0.8);
}

/* Countdown Section */
.countdown-section {
  padding: 100px 20px;
  text-align: center;
}

.countdown-section h2 {
  font-family: 'Exo 2', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: #4d79ff;
}

.countdown-timer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.time-unit {
  margin: 0 10px;
}

.time-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: linear-gradient(145deg, #121236, #0a0a24);
  box-shadow: 0 0 20px rgba(77, 121, 255, 0.3);
  animation: pulse 4s infinite ease-in-out;
}

.number {
  font-family: 'Exo 2', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  color: #4d79ff;
}

.label {
  font-size: 0.9rem;
  color: #a0a0ff;
  margin-top: 5px;
}

/* Timeline Section */
.timeline-section {
  padding: 100px 20px;
  text-align: center;
}

.timeline-section h2 {
  font-family: 'Exo 2', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: #4d79ff;
}

.timeline-container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.timeline-container::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 4px;
  background: linear-gradient(to bottom, #4d79ff, #9d4edd);
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.timeline-item {
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 50px;
  width: 100%;
}

.timeline-item:nth-child(even) {
  justify-content: flex-end;
}

.timeline-icon {
  position: absolute;
  background: #0a0a1a;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 1;
  box-shadow: 0 0 15px rgba(77, 121, 255, 0.5);
}

.timeline-content {
  width: 45%;
  padding: 20px;
  background: rgba(10, 10, 26, 0.8);
  border-radius: 10px;
  border: 1px solid rgba(77, 121, 255, 0.3);
  box-shadow: 0 0 15px rgba(77, 121, 255, 0.2);
  text-align: left;
}

.timeline-content h3 {
  font-family: 'Exo 2', sans-serif;
  color: #4d79ff;
  margin-bottom: 5px;
}

.timeline-content h4 {
  color: #a0a0ff;
  margin-bottom: 10px;
}

.timeline-content ul {
  list-style-position: inside;
  color: #d1d1f0;
}

.timeline-content li {
  margin-bottom: 5px;
}

/* Map Section */
.map-section {
  padding: 100px 20px;
  text-align: center;
}

.map-section h2 {
  font-family: 'Exo 2', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: #4d79ff;
}

.map-container {
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(77, 121, 255, 0.3);
}

/* Footer */
.footer {
  padding: 30px 20px;
  background-color: #05051a;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer p {
  margin-bottom: 20px;
  color: #a0a0ff;
}

.social-links {
  display: flex;
  gap: 20px;
}

.social-icon {
  font-size: 1.5rem;
  color: #4d79ff;
  text-decoration: none;
  transition: all 0.3s ease;
}

.social-icon:hover {
  color: #9d4edd;
  transform: translateY(-3px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .letter {
    font-size: 5rem;
  }
}

@media (max-width: 768px) {
  .letter {
    font-size: 4rem;
  }
  
  .subtitle {
    font-size: 1.2rem;
  }
  
  .time-circle {
    width: 100px;
    height: 100px;
  }
  
  .number {
    font-size: 2rem;
  }
  
  .timeline-container::before {
    left: 30px;
  }
  
  .timeline-item, .timeline-item:nth-child(even) {
    justify-content: flex-start;
    padding-left: 80px;
  }
  
  .timeline-icon {
    left: 30px;
  }
  
  .timeline-content {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .letter {
    font-size: 3rem;
    margin: 0 2px;
  }
  
  .subtitle {
    font-size: 1rem;
  }
  
  .time-circle {
    width: 80px;
    height: 80px;
  }
  
  .number {
    font-size: 1.5rem;
  }
  
  .about-content h2,
  .countdown-section h2,
  .timeline-section h2,
  .map-section h2 {
    font-size: 2rem;
  }
  
  .about-content p {
    font-size: 1rem;
  }
}

/* Dates*/
.calendar-dates {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.calendar-page {
  position: relative;
  width: 100px;
  height: 120px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(77, 121, 255, 0.5), 0 0 30px rgba(77, 121, 255, 0.3);
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform: perspective(500px) rotateY(10deg);
  transition: all 0.3s ease;
}

.calendar-page:nth-child(2) {
  transform: perspective(500px) rotateY(0deg);
  z-index: 2;
}

.calendar-page:nth-child(3) {
  transform: perspective(500px) rotateY(-10deg);
}

.calendar-page:hover {
  transform: perspective(500px) rotateY(0) scale(1.05);
  z-index: 10;
  box-shadow: 0 0 25px rgba(77, 121, 255, 0.8), 0 0 40px rgba(77, 121, 255, 0.4);
}

.month-bar {
  background: linear-gradient(45deg, #4d79ff, #9d4edd);
  color: white;
  text-align: center;
  padding: 4px 0;
  font-family: 'Exo 2', sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.date-number {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Exo 2', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  color: #0a0a1a;
}

.calendar-corner {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: linear-gradient(135deg, transparent 50%, rgba(77, 121, 255, 0.5) 50%);
  border-radius: 0 0 0 5px;
}

/* Update responsive sizes accordingly */
@media (max-width: 768px) {
  .calendar-page {
    width: 80px;
    height: 100px;
  }
  
  .date-number {
    font-size: 2rem;
  }
  
  .month-bar {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .calendar-page {
    width: 65px;
    height: 85px;
  }
  
  .date-number {
    font-size: 1.6rem;
  }
  
  .month-bar {
    font-size: 0.8rem;
  }
}

/* Stats Showcase Styling */
.stats-showcase {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  margin: 40px 0;
}

.stat-item {
  width: 200px;
  padding: 30px 20px;
  background: rgba(10, 10, 26, 0.6);
  border-radius: 20px;
  border: 1px solid rgba(77, 121, 255, 0.3);
  box-shadow: 0 0 20px rgba(77, 121, 255, 0.2);
  text-align: center;
  transition: all 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-8px);
  box-shadow: 0 0 30px rgba(77, 121, 255, 0.5);
  border-color: rgba(77, 121, 255, 0.6);
}

.stat-number {
  font-family: 'Exo 2', sans-serif;
  font-size: 0.9rem;
  font-weight: 700;
  color: #4d79ff;
  margin-bottom: 10px;
  text-shadow: 0 0 15px rgba(77, 121, 255, 0.5);
}

.stat-label {
  font-size: 0.9rem;
  color: #c8c8ff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .stat-item {
    width: 180px;
  }
  
  .stat-number {
    font-size: 0.85rem;
  }
}

@media (max-width: 768px) {
  .stats-showcase {
    gap: 25px;
  }
  
  .stat-item {
    width: 160px;
    padding: 25px 15px;
  }
  
  .stat-number {
    font-size: 0.75rem;
  }
  
  .stat-label {
    font-size: 0.85rem;
  }
}

@media (max-width: 576px) {
  .stats-showcase {
    gap: 15px;
  }
  
  .stat-item {
    width: 130px;
    padding: 20px 10px;
  }
  
  .stat-number {
    font-size: 0.7rem;
  }
  
  .stat-label {
    font-size: 0.75rem;
    letter-spacing: 0.5px;
  }
}

@media (max-width: 375px) {
  .stats-showcase {
    gap: 10px;
  }
  
  .stat-item {
    width: 120px;
    padding: 15px 8px;
  }
  
  .stat-number {
    font-size: 0.rem;
    margin-bottom: 5px;
  }
  
  .stat-label {
    font-size: 0.7rem;
    letter-spacing: 0;
  }
}

/* Updated Footer */
.footer {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 1.5rem 0;
  text-align: center;
  font-family: 'Exo 2', sans-serif;
  letter-spacing: 1px;
  border-top: 1px solid rgba(0, 225, 255, 0.3);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.copyright-text {
  font-size: 0.85rem;
  margin: 0;
}

.social-links {
  display: flex;
  gap: 1.5rem;
}

.social-icon {
  color: white;
  font-size: 1.2rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icon:hover {
  color: #00e1ff;
  transform: translateY(-3px);
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }
  
  .social-links {
    margin-top: 1rem;
  }
}

/* Gallery Section Styles */
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700;800;900&display=swap');

.orbitron-font {
  font-family: 'Exo 2', sans-serif;
  letter-spacing: 1px;
}

.gallery-section {
  padding: 120px 0;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.gallery-section .section-title {
  text-align: center;
  font-size: 3.5rem;
  margin-bottom: 60px;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 0 15px rgba(94, 114, 255, 0.7);
  position: relative;
  z-index: 5;
  font-family: 'Exo 2', sans-serif;
}

.text-accent {
  color: #5E72FF;
  position: relative;
}

.text-accent::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #5E72FF, transparent);
}

.gallery-container {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 5;
}

/* Category Tabs */
.category-tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.category-tab {
  padding: 12px 25px;
  border: 2px solid rgba(94, 114, 255, 0.3);
  background-color: rgba(15, 15, 40, 0.5);
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: 'Exo 2', sans-serif;
}

.category-tab:hover {
  background-color: rgba(94, 114, 255, 0.2);
  border-color: rgba(94, 114, 255, 0.8);
  box-shadow: 0 0 15px rgba(94, 114, 255, 0.4);
}

.category-tab.active {
  background-color: rgba(94, 114, 255, 0.3);
  border-color: #5E72FF;
  box-shadow: 0 0 20px rgba(94, 114, 255, 0.5);
}

/* Featured Event */
.featured-event {
  margin: 80px 0;
  padding: 30px;
  border-radius: 15px;
  background: linear-gradient(135deg, rgba(20, 20, 50, 0.8), rgba(15, 15, 40, 0.95));
  border: 2px solid rgba(94, 114, 255, 0.4);
  box-shadow: 0 0 30px rgba(94, 114, 255, 0.3);
}

.featured-content {
  display: flex;
  align-items: center;
  gap: 40px;
}

.featured-text {
  flex: 1;
  padding: 20px;
}

.featured-text h3 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Exo 2', sans-serif;
}

.highlight {
  color: #5E72FF;
}

.featured-text p {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 30px;
  line-height: 1.5;
  font-family: 'Exo 2', sans-serif;
}

/* Featured Event Stats */
.featured-stats {
  display: flex;
  gap: 40px;
  margin-top: 20px;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-number {
  font-size: 2rem;
  font-weight: 700;
  color: #5E72FF;
  text-shadow: 0 0 10px rgba(94, 114, 255, 0.6);
  font-family: 'Exo 2', sans-serif;
}

.stat-label {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  margin-top: 5px;
  font-family: 'Exo 2', sans-serif;
}

.featured-image-container {
  flex: 1;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid rgba(94, 114, 255, 0.4);
}

.featured-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* CTA Buttons */
.gallery-ctas {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 40px;
}

.cta-button {
  padding: 15px 35px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Exo 2', sans-serif;
}

.primary-cta {
  background: linear-gradient(90deg, #3B3EF7, #5E72FF);
  color: #fff;
  border: 1px solid rgba(94, 114, 255, 0.8);
}

.secondary-cta {
  background: transparent;
  color: #fff;
  border: 2px solid rgba(94, 114, 255, 0.8);
}

.secondary-cta:hover {
  background: rgba(94, 114, 255, 0.2);
  box-shadow: 0 0 15px rgba(94, 114, 255, 0.4);
}
/* Responsive Adjustments */
@media screen and (max-width: 992px) {
  .hex-gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
  
  .featured-content {
    flex-direction: column;
  }
  
  .featured-text, .featured-image-container {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .gallery-section .section-title {
    font-size: 2.5rem;
  }
  
  .category-tabs {
    flex-wrap: wrap;
  }
  
  .category-tab {
    font-size: 0.8rem;
    padding: 10px 20px;
  }
  
  .hex-gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  
  .featured-text h3 {
    font-size: 2rem;
  }
  
  .featured-stats {
    gap: 20px;
  }
  
  .gallery-ctas {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  
  .cta-button {
    width: 100%;
    max-width: 300px;
  }
}

@media screen and (max-width: 480px) {
  .hex-gallery-grid {
    grid-template-columns: 1fr;
  }
  
  .category-tab {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .featured-text h3 {
    font-size: 1.8rem;
  }
  
  .featured-image-container {
    height: 200px;
  }
}

@keyframes lineGrow {
  0% {
    height: 10%;
    opacity: 0.3;
  }
  100% {
    height: 100%;
    opacity: 0.7;
  }
}

/* Neon Heading Styles */
.neon-heading {
  text-align: center;
  margin: 3rem 0;
  font-size: 2.8rem;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
}

.neon-text {
  color: #fff;
  text-shadow: 0 0 5px #5E72FF, 
               0 0 10px #5E72FF, 
               0 0 20px #5E72FF, 
               0 0 30px #5E72FF, 
               0 0 40px #3B3EF7, 
               0 0 55px #3B3EF7, 
               0 0 70px #3B3EF7;
  animation: neon-pulse 1.5s infinite alternate;
  position: relative;
  z-index: 2;
  letter-spacing: 2px;
}

.neon-text::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  z-index: -1;
  color: transparent;
  text-shadow: 0 0 5px rgba(94, 114, 255, 0.3),
               0 0 10px rgba(94, 114, 255, 0.3),
               0 0 15px rgba(94, 114, 255, 0.3);
  opacity: 0.8;
}

@keyframes neon-pulse {
  0%, 40%, 60%, 70%, 90% {
    opacity: 1;
  }
  45%, 65%, 75%, 85% {
    opacity: 0.8;
    text-shadow: 0 0 5px #5E72FF,
                 0 0 10px #5E72FF,
                 0 0 15px #5E72FF;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .neon-heading {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .neon-heading {
    font-size: 1.5rem;
  }
}

/* Fix for Glimpses of Sparsh 2024 section */
.featured-content {
  padding: 0 40px; /* Add equal padding on both sides */
  max-width: 1200px;
  margin: 0 auto; /* Center the content */
}

.featured-images-container {
  display: flex;
  gap: 30px; /* Increased gap between images */
  flex: 1;
  margin-top: 20px; /* Add space above the images */
}

.featured-images-container .featured-image {
  width: 50%;
  height: 300px; /* Fixed height for all images */
  object-fit: cover;
  border-radius: 10px;
  transform: none; /* Remove the perspective/tilting effect */
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Add shadow for depth instead of tilt */
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .featured-content {
    padding: 0 20px; /* Smaller padding on mobile */
  }
  
  .featured-images-container {
    flex-direction: column;
  }
  
  .featured-images-container .featured-image {
    width: 100%;
    height: 250px; /* Slightly smaller height on mobile */
    margin-bottom: 20px;
  }
}

/* Add these styles to your landing.css file */

@media only screen and (max-width: 768px) {
  .about-section, .theme-section {
      margin-left: 16px;
      margin-right: 16px;
  }
}

/* Add these media queries to your landing.css file */

@media only screen and (max-width: 768px) {
  /* Mobile header and title adjustments */
  .sparsh-title {
    padding: 20px 10px;
  }
  
  .letter-container {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }
  
  .subtitle {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  /* Calendar layout adjustments */
  .calendar-dates {
    flex-direction: row;
    justify-content: center;
    margin: 10px auto;
  }
  
  .calendar-page {
    width: 28%;
    margin: 0 2%;
    font-size: 0.9rem;
  }
  
  /* Countdown timer adjustments */
  .countdown-timer {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .time-unit {
    margin: 5px;
  }
  
  .time-circle {
    width: 65px;
    height: 65px;
  }
  
  .number {
    font-size: 1.2rem;
  }
  
  .label {
    font-size: 0.7rem;
  }
  
  /* About section and stats adjustments */
  .about-content {
    padding: 20px 15px;
  }
  
  .stats-showcase {
    flex-direction: column;
    align-items: center;
  }
  
  .stat-item {
    width: 100%;
    margin: 10px 0;
  }
  
  /* Timeline adjustments */
  .timeline-container {
    flex-direction: column;
  }
  
  .timeline-item {
    width: 90%;
    margin: 10px auto;
  }
  
  /* Gallery section adjustments */
  .featured-content {
    flex-direction: column;
  }
  
  .featured-text, .featured-images-container {
    width: 100%;
  }
  
  .featured-images-container {
    flex-direction: column;
  }
  
  .featured-image {
    width: 100%;
    margin: 5px 0;
  }
  
  /* Button sizing and spacing */
  .register-btn {
    width: 100%;
    text-align: center;
    padding: 12px 0;
  }
  
  /* Map container */
  .map-container {
    padding: 0 10px;
  }
  
  /* Neon text effects scaling */
  .neon-heading {
    font-size: 1.8rem;
  }
}

/* Small phones adjustment */
@media only screen and (max-width: 480px) {
  .letter-container {
    font-size: 2rem;
  }
  
  .time-circle {
    width: 55px;
    height: 55px;
  }
  
  .number {
    font-size: 1rem;
  }
  
  .label {
    font-size: 0.6rem;
  }
  
  .calendar-page {
    width: 30%;
    margin: 0 1%;
  }
  
  .date-number {
    font-size: 1.2rem;
  }
}